import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal/Modal.jsx';
import Trans from '../../../components/Ui/Trans.jsx';
import ActionLink from '../../../components/Ui/ActionLink.jsx';
import classNames from 'classnames';


/**
 * cookie bar variant Popup
 */
export default function Popup({
	open,
	cookieDisclosureUrl,
	expandedManage,
	types,
	handleToggleManage,
	handleAcceptAll,
	handleToggleCheckbox,
	handleAcceptSelected,
	handleClose,
	popupCheckboxAnimation,
})
{
	// if cookie bar is closed, do not render it
	if (!open)
	{
		return null;
	}

	const buttonAcceptAll =
		<ActionLink className="w-button type-action">
			<span className="w-cookie-modal-accept-cookies" onClick={handleAcceptAll}>
				<Trans i18nKey="text[portal-2015][cookiebar][acceptAll]" defaults="Accept all" />
			</span>
		</ActionLink>;

	const buttonAcceptSelected =
		<ActionLink
			className="w-button type-action"
			onClick={handleAcceptSelected}
		>
			<span>
				{
					expandedManage
						?
						// "accept selected" (checkboxes visible)
						<Trans
							i18nKey="text[portal-2015][cookiebar][acceptSelected]"
							defaults="Accept selected"
						/>
						:
						// "accept necessary" (checkboxes invisible)
						<Trans
							i18nKey="text[portal-2015][cookiebar][acceptNecessary]"
							defaults="Accept necessary"
						/>
				}
			</span>
		</ActionLink>;

	return (
		<>
			<Modal
				onClose={handleClose}
				showCloseButton={true}
				closeOnOverlay={false}
			>

				<Modal.Section className="w-cookie-modal">
					<Modal.Header>
						<h3 className="w-cookie-heading">
							<Trans i18nKey="text[portal-2015][cookiebar][header]" defaults="This website uses cookies"/>
						</h3>
					</Modal.Header>


					<Modal.Content>
						<p>
							<Trans
								i18nKey="text[portal-2015][cookiebar][content]"
								defaults="We use cookies to enable the proper functioning and security of our websites,
										and help us offer you the best possible user experience. By clicking Accept,
										you consent to the use of these cookies for advertising and analytics.
										You can change your cookie settings at any time. For more information, please
										read more about the <link1>cookies we use</link1>."
								components={{
									link1: <a href={cookieDisclosureUrl} target="_blank" className="w-cookie-disclosure-link" rel="noreferrer" />,
								}}
							/>
						</p>

						<div
							className={
								classNames(
									'w-cookie-modal-content-action',
									{
										'state-expanded': expandedManage,
										'state-collapsed': !expandedManage,
									},
								)
							}>

							{/* "manage my choices" text link */}
							<p className="w-cookie-modal-content-action-trigger w-cookie-manage-choices">
								<ActionLink onClick={handleToggleManage} className="w-cookie-manage-choices-link">
									<Trans
										i18nKey="text[portal-2015][cookiebar][manageMyChoices]"
										defaults="Manage my choices"
									/>
								</ActionLink>
							</p>

							{/* checkboxes for cookies choice */}
							<div
								className="w-cookie-modal-content-action-submenu"
								style={popupCheckboxAnimation ? {
									opacity: 1,
								} : {}}
							>
								<form className="form" action="#" method="post">
									<div className="w-checkbox">
										<label>
											<input
												className="state-disabled"
												type="checkbox"
												name="essential"
												id="essential"
												checked={types.essential}
												disabled=""
											/>
											<span className="w-checkbox-outer" />
											<span className="w-checkbox-label-text">
												<Trans
													i18nKey="text[portal-2015][cookiebar][essential]"
													defaults="Essential" />
											</span>
										</label>
									</div>
									<div className="w-checkbox">
										<label>
											<input
												type="checkbox"
												name="functional"
												id="functional"
												checked={types.functional}
												onChange={() => handleToggleCheckbox('functional')}
											/>
											<span className="w-checkbox-outer" />
											<span className="w-checkbox-label-text">
												{/* wrong identifier, actually it's marketing */}
												<Trans
													i18nKey="text[portal-2015][cookiebar][performance]"
													defaults="Marketing/third party" />
											</span>
										</label>
									</div>
								</form>
							</div>
						</div>
					</Modal.Content>

					<Modal.Footer>
						<div className="w-cookie-modal-footer">

							{buttonAcceptAll}{buttonAcceptSelected}

						</div>
					</Modal.Footer>
				</Modal.Section>
			</Modal>
		</>
	);
}

Popup.propTypes = {
	open: PropTypes.bool.isRequired,
	cookieDisclosureUrl: PropTypes.string.isRequired,
	expandedManage: PropTypes.bool.isRequired,
	animationCompleted: PropTypes.bool.isRequired,
	types: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
	handleToggleManage: PropTypes.func.isRequired,
	handleAcceptAll: PropTypes.func.isRequired,
	handleToggleCheckbox: PropTypes.func.isRequired,
	handleAcceptSelected: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	popupCheckboxAnimation: PropTypes.bool,
};
