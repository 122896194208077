import React from 'react';
import PropTypes from 'prop-types';
import FormSection from "./FormSection";
import Layout from "../Layout/Layout";

export default class Login extends React.Component
{
	/**
	 * Vykreslí komponentu se stránkou
	 *
	 * @returns {JSX.Element}
	 */
	render()
	{
		return (
			<Layout showFooterColumns={true}>
				<main>
					<FormSection
						onChange={this.props.onChange}
						termsAndConsUrl={this.props.termsAndConsUrl}
						onSubmit={this.props.onSubmit}
						privacyUrl={this.props.privacyUrl}
						onSocial={this.props.onSocial}
						registerUrl={this.props.registerUrl}
						inputs={this.props.inputs}
						captchaChallenge={this.props.captchaChallenge}
						trackEvent={this.props.trackLoginEvent}
						loginHelpUrl={this.props.loginHelpUrl}
						forgotPasswordUrl={this.props.forgotPasswordUrl}
						upgradeNotificationUrl={this.props.upgradeNotificationUrl}
						anonymousContactFormUrl={this.props.anonymousContactFormUrl}
						isFreeEmailDeflectionReleased={this.props.isFreeEmailDeflectionReleased}
					/>
				</main>
			</Layout>
		);
	}
}

Login.propTypes = {

	inputs: PropTypes.shape({
		email: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
		password: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
	}).isRequired,

	registerUrl: PropTypes.string.isRequired,
	upgradeNotificationUrl: PropTypes.string,
	privacyUrl: PropTypes.string.isRequired,
	loginHelpUrl: PropTypes.string.isRequired,
	termsAndConsUrl: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onSocial: PropTypes.func.isRequired,
	trackLoginEvent: PropTypes.func.isRequired,
	forgotPasswordUrl: PropTypes.string,
	captchaChallenge: PropTypes.bool,
	isFreeEmailDeflectionReleased: PropTypes.bool,
	anonymousContactFormUrl: PropTypes.string,
};