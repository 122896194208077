import {fetchGraphQL} from '../../utils/graphql';
import {checkoutOrderFieldsFragment, paymentMethodFieldsFragment} from "./fragments";

export default {
	/**
	 * @param {string} orderIdentifier
	 * @param {boolean} lastReloadBeforeSubmit
	 * @return {Promise<any>}
	 */
    getOrderAndPaymentMethods: (orderIdentifier, lastReloadBeforeSubmit) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            ${paymentMethodFieldsFragment}
            query(
            	$orderIdentifier: String!
                $lastReloadBeforeSubmit: Boolean!
			) {
                webnodeOrder(order: {identifier: $orderIdentifier}) {
                    ...CheckoutOrderFields
                }

                webnodePaymentMethods(
                    order: {identifier: $orderIdentifier}
                    lastReloadBeforeSubmit: $lastReloadBeforeSubmit
                ) {
                    ...PaymentMethodFields
                }
            }
        `,
        {
            orderIdentifier,
			lastReloadBeforeSubmit,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {boolean} lastReloadBeforeSubmit
	 * @param {string|null} merchantTransactionId
	 * @param {string|null} methodId
	 * @param {string|null} ref
	 * @param {string|null} token
	 * @return {Promise<any>}
	 */
	getPaymentMethods: (
		orderIdentifier,
		lastReloadBeforeSubmit,
		merchantTransactionId,
		methodId,
		ref,
		token,
	) => fetchGraphQL(/* GraphQL */`
            ${paymentMethodFieldsFragment}
            query(
                $orderIdentifier: String!
                $lastReloadBeforeSubmit: Boolean!
                $merchantTransactionId: String
                $methodId: String
                $ref: String
                $token: String
            ){
                webnodePaymentMethods(
                    order: {identifier: $orderIdentifier}
                    lastReloadBeforeSubmit: $lastReloadBeforeSubmit
                    merchantTransactionId: $merchantTransactionId
                    methodId: $methodId
                    ref: $ref
                    token: $token
                    
                ) {
                    ...PaymentMethodFields
                }
            }
        `,
        {
            orderIdentifier,
            lastReloadBeforeSubmit,
			merchantTransactionId,
			methodId,
			ref,
			token,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {string} itemIdentifier
	 * @param {string|null} period
	 * @param {null|string} integrityToken
	 * @return {Promise<any>}
	 */
    addOrderAdditionalItem: (
		orderIdentifier,
		itemIdentifier,
		period,
		integrityToken
    ) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            mutation(
                $orderIdentifier: String!
                $period: String
                $integrityToken: String
            ) {
                addOrderAdditionalItem(
                    order: {identifier: $orderIdentifier}
                    itemIdentifier: ${itemIdentifier}
                    period: $period
                    integrityToken: $integrityToken
                ) {
                    ...CheckoutOrderFields
                }
            }
        `,
        {
            orderIdentifier,
            period,
			integrityToken,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {string} hash
	 * @param {null|string} integrityToken
	 * @return {Promise<any>}
	 */
    deleteOrderAdditionalItem: (
		orderIdentifier,
		hash,
		integrityToken
    ) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            mutation(
                $orderIdentifier: String!
                $hash: String!
                $integrityToken: String
            ) {
                deleteOrderAdditionalItem(
                    order: {identifier: $orderIdentifier}
                    hash: $hash
                    integrityToken: $integrityToken
                ) {
                    ...CheckoutOrderFields
                }
            }
        `,
        {
            orderIdentifier,
            hash,
			integrityToken,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {string} voucher
	 * @param {string} integrityToken
	 * @return {Promise<any>}
	 */
    updateOrderVoucher: (orderIdentifier, voucher, integrityToken) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            mutation(
                $orderIdentifier: String!
                $voucher: String
                $integrityToken: String
            ){
                updateOrderVoucher(
                    order: {identifier: $orderIdentifier}
                    code: $voucher
                    integrityToken: $integrityToken
                ) {
                    ...CheckoutOrderFields
                }
            }
        `,
        {
            orderIdentifier,
            voucher,
            integrityToken,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {string} domain
	 * @param {boolean} enable
	 * @param {string} period
	 * @param {string} integrityToken
	 * @return {Promise<any>}
	 */
    updateOrderPrivateRegistration: (
		orderIdentifier,
		domain,
		enable,
		period,
		integrityToken,
    ) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            mutation(
                $orderIdentifier: String!
                $domain: String!
                $period: String!
                $enable: Boolean!
                $integrityToken: String
            ){
                updateOrderPrivateRegistration(
                    order: {identifier: $orderIdentifier}
                    domain: $domain
                    enable: $enable
                    period: $period
                    integrityToken: $integrityToken
                ) {
                    ...CheckoutOrderFields
                }
            }
        `,
        {
            orderIdentifier,
            domain,
            enable,
            period,
            integrityToken,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {string} hash
	 * @param {string} period
	 * @param {number} units
	 * @param {string} integrityToken
	 * @return {Promise<any>}
	 */
    updateOrderServicePeriod: (
		orderIdentifier,
		hash,
		period,
		units,
		integrityToken,
    ) => fetchGraphQL(/* GraphQL */`
            ${checkoutOrderFieldsFragment}
            mutation(
                $orderIdentifier: String!
                $hash: String!
                $period: String!
                $units: Int!
                $integrityToken: String
            ){
                updateOrderServicePeriod(
                    order: {identifier: $orderIdentifier}
                    itemIdentifier: $hash
                    period: $period
                    units: $units
                    integrityToken: $integrityToken
                ) {
                    ...CheckoutOrderFields
                }
            }
        `,
        {
            orderIdentifier,
            hash,
            period,
            units,
            integrityToken,
        }
    ),
	/**
	 * @param {string} orderIdentifier
	 * @param {null|{
	 * 	contactFields: Array<{name: string, value: string}>
	 * }} contact
	 * @param {string|null} paymentServiceName
	 * @param {string} integrityToken
	 * @return {Promise<any>}
	 */
	finishOrder: (
		orderIdentifier,
		contact,
		paymentServiceName,
		integrityToken,
	) => fetchGraphQL(/* GraphQL */`
			${checkoutOrderFieldsFragment}
			mutation(
				$orderIdentifier: String!
                $contact: OrderContactInput
				$paymentServiceName: String
                $integrityToken: String
			){
				finishOrder(
					order: {identifier: $orderIdentifier}
					contact: $contact
					paymentServiceName: $paymentServiceName
                    integrityToken: $integrityToken
				) {
					...CheckoutOrderFields
				}
			}
		`,
		{
			orderIdentifier,
			contact,
			paymentServiceName,
			integrityToken,
		}
	),

	/**
	 * @param {string} orderIdentifier
	 * @param {null|{
	 *    contactFields: Array<{name: string, value: string}>
	 * }} contact
	 * @param {string} integrityToken
	 * @return {Promise<any>}
	 */
	updateBillingDataAndRecalculateVat: (
		orderIdentifier,
		contact,
		integrityToken,
	) => fetchGraphQL(/* GraphQL */`
			${checkoutOrderFieldsFragment}
			mutation(
				$orderIdentifier: String!
				$contact: OrderContactInput!
				$integrityToken: String
			){
				updateBillingDataAndRecalculateVat(
					order: {identifier: $orderIdentifier}
					contact: $contact
					integrityToken: $integrityToken
				) {
					...CheckoutOrderFields
				}
			}
		`,
	{
		orderIdentifier,
		contact,
		integrityToken,
	}
	),
};
