import React from 'react';
import i18n from 'i18next';
import Trans from '../../components/Ui/Trans';
import SocialFormSection from '../../components/Ui/Layout/SocialFormSection';
import {CustomCareInfo, TermsOfUse} from '../../Modules/Login/LoginParts';
import PropTypes from 'prop-types';
import {EmailField, PasswordField} from '../../Modules/Register/inputs';
import UnsafeHtml from '../../components/Ui/UnsafeHtml';
import ReCaptchaV2 from '../../components/Ui/ReCaptcha/ReCaptchaV2';
import UpgradeNotificationShoutBoard from '../../components/Ui/SystemNotifications/UpgradeNotificationShoutBoard.jsx';



export default function FormSection(
	{
		registerUrl,
		privacyUrl,
		termsAndConsUrl,
		inputs,
		onChange,
		onSubmit,
		onSocial,
		captchaChallenge,
		trackEvent,
		loginHelpUrl,
		forgotPasswordUrl,
		upgradeNotificationUrl,
		anonymousContactFormUrl,
		isFreeEmailDeflectionReleased,
	}
)
{
	let emailError = inputs.email?.error;
	if (emailError)
	{
		emailError = <>
			<UnsafeHtml content={emailError} elem="span"/>
			{' '}
			<a href={loginHelpUrl}>
				{i18n.t(
					'text[portal-2015][loginPage][main][errorKbLink]',
					'Need help with singing in?'
				)}
			</a>
		</>;
	}

	return (

		<div className="user-page user-page-wide user-log-credentials">
			<div className="section-inner">

				<h1>
					{i18n.t('text[portal-2015][loginPage][main][title]', 'Login')}
				</h1>
				<h3>
					<Trans
						components={{a: <a href={registerUrl} className="signup-submit" data-ref="login-page-sign-up-button" />}}
						defaults={'Need an account? <a>Sign up</a>'}
						i18nKey={'text[portal-2015][loginPage][main][subTitle]'}
					/>
				</h3>

				<form
					id="pageLogin"
					className="form"
					method="post"
					encType="multipart/form-data"
					noValidate="novalidate"
					onSubmit={(e) =>
					{
						e.preventDefault();
						onSubmit();
					}}
				>
					<div className="user-page-log-container">
						<SocialFormSection onClick={onSocial} className="user-page-log-social"/>

						<div className="user-page-log-classic">

							<div className="user-page-log-delimiter">
								<h4>
									<span>
										{i18n.t('text[portal-2015][registration][orEmail]', 'or with your email')}
									</span>
								</h4>
							</div>

							<EmailField
								onChange={onChange}
								onTrack={trackEvent}
								data={{
									...(inputs.email ?? {}),
									error: emailError,
								}}
								id="field-user"
							/>
							<PasswordField
								onChange={onChange}
								onTrack={trackEvent}
								data={inputs.password}
								id="field-password"
								label={i18n.t('text[portal-2015][loginPage][form][passwordTitle]', 'Password')}
							/>
							<div className="form-text">
								<a className="link" href={forgotPasswordUrl}>
									{i18n.t('text[portal-2015][loginPage][form][passwordForgot]', 'Forgot your password?')}
								</a>
							</div>

							{
								captchaChallenge &&
								<ReCaptchaV2
									onChange={response => onChange('g-recaptcha-response', response)}
								/>
							}
							<div className="form-submit-classic">
								<button type="submit">
									{i18n.t('text[portal-2015][loginPage][form][submitButton]', 'Login')}
								</button>
							</div>
						</div>

					</div>

					<div className="user-page-log-terms-of-use">
						{
							upgradeNotificationUrl &&
							<UpgradeNotificationShoutBoard kbLink={upgradeNotificationUrl}/>
						}

						{
							isFreeEmailDeflectionReleased && anonymousContactFormUrl &&
							<CustomCareInfo
								anonymousContactFormUrl={anonymousContactFormUrl}
							/>
						}

						<TermsOfUse
							privacyUrl={privacyUrl}
							termsAndConsUrl={termsAndConsUrl}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

FormSection.propTypes = {
	registerUrl: PropTypes.string.isRequired,
	privacyUrl: PropTypes.string.isRequired,
	loginHelpUrl: PropTypes.string.isRequired,
	termsAndConsUrl: PropTypes.string.isRequired,
	upgradeNotificationUrl: PropTypes.string,
	inputs: PropTypes.shape({
		email: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
		password: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onSocial: PropTypes.func.isRequired,
	trackEvent: PropTypes.func.isRequired,
	forgotPasswordUrl: PropTypes.string,
	anonymousContactFormUrl: PropTypes.string,
	captchaChallenge: PropTypes.bool,
	isFreeEmailDeflectionReleased: PropTypes.bool,
};
