import {productionLog} from "../../utils/utils";

export {
	closeModal,
	sendRegister,
	sendRegisterSocial,
	changeLoginInput,
	changeRegisterInput,
	setLoginError,
	setRegisterError,
	trackLoginEvent,
	trackRegisterEvent,

	MODAL_SECTION_REGISTRATION,
	MODAL_SECTION_LOGIN,
	openRegisterModal,

} from '../../Modules/RegisterLoginModal/actions';

import {
	openRegisterModal,
	sendLoginSocial as sendLoginSocialFinish,
	sendLogin as sendLoginFinnish,
} from '../../Modules/RegisterLoginModal/actions';

export const sendLogin = (formState) => (dispatch) =>
{
	dispatch(openRegisterModal());
	dispatch(sendLoginFinnish(undefined, formState));
}

export const sendLoginSocial = (url, type, formState)  => (dispatch) =>
{
	dispatch(openRegisterModal());
	dispatch(sendLoginSocialFinish(url, type, formState));
}
