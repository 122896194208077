import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from './actions';
import Login from './Login';
import {registerEvents} from '../../Modules/Register/module';
import PropTypes from 'prop-types';

/**
 * Login page
 */
class LoginPage extends React.Component
{

	componentDidMount()
	{
		if (this.props.staticData.inputs)
		{
			for (let name of Object.keys(this.props.staticData.inputs))
			{
				this.props.changeLoginInput(name, this.props.staticData.inputs[name].value ||'');
				if (this.props.staticData.inputs[name].error !== null)
				{
					this.props.setLoginError(name, this.props.staticData.inputs[name].error);
					// browser někdy ten error smaže autofillem, tak ho tam zas přihodíme
					setTimeout(() => this.props.setLoginError(name, this.props.staticData.inputs[name].error), 5);
				}
			}
		}
		registerEvents(this.props.openRegisterModal);
		// delegace dat pro jsAntiSpam
		const jsAntiSpamData = this.props.staticData.jsAntiSpamData;
		this.props.changeRegisterInput(jsAntiSpamData['id'], jsAntiSpamData['value']);
		this.props.changeLoginInput(jsAntiSpamData['id'], jsAntiSpamData['value']);

		this.props.changeRegisterInput('clickSource', 'login-page');
		this.props.changeLoginInput('clickSource', 'login-page');

		this.props.changeRegisterInput('source', 'modal');
		this.props.changeLoginInput('source', 'page');

		if (this.props.staticData.captchaChallenge)
		{
			this.props.changeRegisterInput('g-recaptcha-response', '');
			this.props.changeLoginInput('g-recaptcha-response', '');
		}
	}

	/**
	 * Render
	 */
	render()
	{
		const {
			registerUrl,
			privacyUrl,
			termsAndConsUrl,
			socialUrl,
			captchaChallenge,
			loginHelpUrl,
			forgotPasswordUrl,
			upgradeNotificationUrl,
			anonymousContactFormUrl,
			isFreeEmailDeflectionReleased,
		} = this.props.staticData;

		const redirectUri = new URLSearchParams(this.props.location.search).get('redirect_uri');

		return (
			<Login
				inputs={this.props.inputs}
				modal={this.props.modal}
				upgradeNotificationUrl={upgradeNotificationUrl}
				onChange={this.props.changeLoginInput}
				onSubmit={() => this.props.sendLogin({
					inputs: this.props.inputs,
					data: this.props.data,
					trackingData: this.props.trackingData,
				})}
				onSocial={(type) => this.props.sendLoginSocial(
					socialUrl,
					type,
					{
						inputs: this.props.inputs,
						data: this.props.data,
						trackingData: this.props.trackingData,
						redirectUri: redirectUri,
					}
				)}
				privacyUrl={privacyUrl}
				termsAndConsUrl={termsAndConsUrl}
				registerUrl={registerUrl}
				captchaChallenge={captchaChallenge}
				trackLoginEvent={this.props.trackLoginEvent}
				loginHelpUrl={loginHelpUrl}

				forgotPasswordUrl={forgotPasswordUrl}
				anonymousContactFormUrl={anonymousContactFormUrl}
				isFreeEmailDeflectionReleased={isFreeEmailDeflectionReleased}
			/>
		);
	}
}


export default connect(
	(state, ownProps) => ({...state.loginForm, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch),
)(LoginPage);


LoginPage.propTypes = {

	inputs: PropTypes.shape({
		email: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
		password: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
	}).isRequired,

	staticData: PropTypes.shape({
		registerUrl: PropTypes.string.isRequired,
		privacyUrl: PropTypes.string.isRequired,
		termsAndConsUrl: PropTypes.string.isRequired,
		socialUrl: PropTypes.string,
		captchaChallenge: PropTypes.bool,
		loginHelpUrl: PropTypes.string.isRequired,
		forgotPasswordUrl: PropTypes.string,
		upgradeNotificationUrl: PropTypes.string,
		isFreeEmailDeflectionReleased: PropTypes.bool,
		anonymousContactFormUrl: PropTypes.string,
	}).isRequired,

	location: PropTypes.shape({
		search: PropTypes.string,
	}).isRequired,

	modal: PropTypes.object.isRequired,
	sendLogin: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	trackingData: PropTypes.object.isRequired,
	sendLoginSocial: PropTypes.func.isRequired,
	trackLoginEvent: PropTypes.func.isRequired,
};