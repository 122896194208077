import './utils/polyfill/polyfills'; // musí být první
import './utils/sentry';
import { combineReducers } from 'redux';
import render from './utils/render';
import reducer from './Pages/Login/reducer';
import LoginPage from './Pages/Login/LoginPage';
import registerForm from './Modules/Register/reducer';
import loginForm from './Modules/Login/reducer';
import modal from './components/Modal/reducer';
import layoutReducer from './Pages/Layout/reducer';

const rootReducer = combineReducers({
	page: reducer,
	layout: layoutReducer,
	registerForm,
	loginForm,
	modal,
});

// vykreslime stranku
render(LoginPage, rootReducer);