import * as actions from './actions';

const initialState = {};


export default function (state = initialState, action)
{
	switch (action.type)
	{
		default:
			return state;
	}
}